<template>
  <b-container class="ml-auto mb-2">
    <div v-if="$acl.can('usersroles.users.create')">
      <portal to="nav-bar-left-column">
        <page-title title="Users" subtitle="Create new user" />
      </portal>
      <portal to="nav-bar-right-column"> </portal>
      <b-card>
        <form-alert :errors="serverErrors" />
        <validation-observer ref="userForm">
          <b-form @submit.prevent>
            <b-row>
              <!-- names -->
              <b-col cols="6">
                <text-input v-model="user.firstName" placeholder="First Name" label="First Name" validation-rules="required" />
              </b-col>

              <b-col cols="6">
                <text-input v-model="user.lastName" placeholder="Last Name" label="Last Name" validation-rules="required" />
              </b-col>

              <!-- email -->
              <b-col cols="6">
                <text-input v-model="user.email" placeholder="Email" label="Email" validation-rules="email" type="email" />
              </b-col>

              <!-- mobile -->
              <b-col cols="6">
                <text-input v-model="user.mobilePhone" placeholder="Mobile" label="Mobile" validation-rules="" type="text" />
              </b-col>

              <!-- username -->
              <b-col cols="12">
                <text-input v-model="user.username" placeholder="Username" label="Username" validation-rules="required" type="text" />
              </b-col>

              <!-- password -->
              <b-col cols="6">
                <text-input v-model="user.password" placeholder="Password" label="Password" validation-rules="required" type="password" vid="password" />
              </b-col>

              <b-col cols="6">
                <text-input
                  v-model="confirmPassword"
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  validation-rules="required|confirmpassword:password"
                  type="password"
                />
              </b-col>

              <b-col cols="6">
                <validation-provider rules="required" name="roles" v-slot="{ errors }" tag="div">
                  <b-form-group label="Roles" label-for="roles" :class="errors.length > 0 ? 'is-invalid' : null">
                    <v-select id="roles" multiple v-model="user.roles" :options="roles" :reduce="role => role.id" label="name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- status -->
              <b-col cols="12">
                <b-form-group>
                  <div class="inline-spacing">
                    <b-form-checkbox id="isActive" name="isActive" v-model="user.isActive">
                      Is Active
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>

              <!-- reset and submit -->
              <b-col cols="12" class="mt-1">
                <b-button type="submit" variant="primary" @click="onSave">
                  Save
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
    <div v-else><forbidden />></div>
  </b-container>
</template>
<script>
import { users } from '@/api/index'
import vSelect from 'vue-select'
import DefaultNotifications from '@/components/Notification/default'
import { extend } from 'vee-validate'
import { confirmed } from 'vee-validate/dist/rules'

extend('confirmpassword', {
  ...confirmed,
  message: 'The passwords do not match',
})

export default {
  name: 'NewUser',
  components: {
    vSelect,
  },
  data() {
    return {
      user: { roles: ['registered-users'], isActive: true, attributes: {} },
      roles: [],
      formErrors: false,
      serverErrors: [],
      confirmPassword: '',
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {
    this.fetchRoles()
  },
  methods: {
    async fetchRoles() {
      const self = this
      users.getRoles().then(res => {
        self.roles = res.data
      })
    },
    async onSave() {
      var self = this

      var validation = await self.$refs.userForm.validateWithInfo()

      if (validation.isValid) {
        users
          .createUser(self.user)
          .then(res => {
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            this.$router.push({ name: 'EditUser', params: { id: res.data.result } })
          })
          .catch(err => {
            self.formErrors = true
            if (err.response.status == 422) {
              self.serverErrors = err.response.data.errors
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      }
    },
  },
}
</script>

