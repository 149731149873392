<template>
  <div>
    <b-card>
      <form-alert :errors="serverErrors" />
      <portal to="nav-bar-right-column">
        <div class="d-flex align-items-center">
          <span v-if="formErrors" class="mr-1 d-flex align-items-center text-danger">
            <feather-icon icon="AlertCircleIcon" class="m-0 p-0 ml-1 mr-25" />There are errors on the page that prevent to save changes
          </span>
          <b-button type="submit" variant="outline-dark" class="mr-1" @click="$emit('cancel')"> Cancel </b-button>
          <b-button type="submit" :variant="saveButtonVariant" class="mr-1" @click="onSave" :disabled="!(isDirty && $acl.can('usersroles.roles.update'))">
            Save
          </b-button>
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="confirmDelete = true" v-if="$acl.can('usersroles.roles.delete')">
              <feather-icon icon="TrashIcon" class="text-danger darken-2" />
              <span class="align-middle ml-50 text-danger text-darken-2">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </portal>
      <fieldset :disabled="!$acl.can('usersroles.roles.update')">
        <validation-observer ref="roleForm">
          <b-form v-on:keyup.enter="onFormEnterKey" @submit.prevent>
            <b-row>
              <!-- names -->
              <b-col cols="6">
                <text-input v-model="role.name" placeholder="Name" label="Name" validation-rules="required" :readonly="role.system" />
              </b-col>
            </b-row>
          </b-form>
          <b-row>
            <b-col cols="12">
              <b-card no-body class="border mt-1">
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="LockIcon" size="14" />
                    <span class="align-middle ml-50">Permission</span>
                  </b-card-title>
                </b-card-header>
                <b-table-simple striped responsive v-for="(area, index) in permissions" :key="'sp_' + index" class="position-relative mb-0">
                  <b-thead>
                    <b-th class="action-cell-medium" align="center"> </b-th>
                    <b-th style="width:300px">
                      <span class="text-uppercase">{{ area.group }}</span>
                    </b-th>
                    <b-th>
                      <span class="text-uppercase">Permissions</span>
                    </b-th>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(section, sectionIndex) in area.sections" :key="'sp_' + index + '_' + sectionIndex">
                      <b-td class="action-cell-medium text-center">
                        <b-button size="sm" variant="link" @click="deselectAll(area, section)" v-if="isAllSelected(area, section)">Deselect</b-button>
                        <b-button size="sm" variant="primary" @click="selectAll(area, section)" v-else>Select</b-button>
                      </b-td>
                      <b-td class="py-1">
                        {{ section.name }}
                      </b-td>
                      <b-td class="py-1">
                        <div class="inline-spacing">
                          <b-form-checkbox
                            v-for="permission in section.permissions"
                            :key="'sp_' + index + '_' + sectionIndex + '_' + permission"
                            :name="permission"
                            :checked="getPermissionIndex(area, section, permission) > -1"
                            @change="onSelectPermission($event, area, section, permission)"
                          >
                            {{ permission }}
                          </b-form-checkbox>
                        </div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </fieldset>
    </b-card>
    <b-modal id="deleteConfirmModal" hide-footer title="Role" size="md" centered hide-header v-model="confirmDelete">
      <div class="d-block text-left mt-2">
        <h4>Delete Role</h4>
        <p class="h6 font-weight-normal">Do you want to delete the role?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDelete = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDelete">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { users } from '@/api/index'
import DefaultNotifications from '@/components/Notification/default'

export default {
  name: 'EditRole',
  props: ['value'],
  components: {},
  data() {
    return {
      permissions: [],
      confirmDelete: false,
      isDirty: false,
      role: { permissions: [] },
      formErrors: false,
      serverErrors: [],
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.original = this.value
        this.role = this.$_.cloneDeep(this.original)
      },
    },
    role: {
      deep: true,
      handler(newValue) {
        this.isDirty = !this.$_.isEqual(newValue, this.original)
      },
    },
  },
  computed: {
    saveButtonVariant() {
      return this.isDirty ? 'primary' : 'outline-secondary'
    },
  },
  mounted() {},
  created() {
    this.fetchPermissions()
  },
  methods: {
    async fetchPermissions() {
      const self = this
      await users.getPermissionSet().then(res => {
        self.permissions = res.data
      })
    },
    async onFormEnterKey() {
      const self = this
      if (self.isDirty > 0 && self.$acl.can('usersroles.roles.update')) {
        await self.onSave()
      }
    },
    async onSave() {
      const self = this

      const validation = await self.$refs.roleForm.validateWithInfo()

      if (validation.isValid) {
        users
          .updateRole(self.role.id, self.role)
          .then(res => {
            self.formErrors = false
            self.serverErrors = []
            self.$emit('input', { ...self.role })
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.serverErrors = err.response.data.errors
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.formErrors = true
      }
    },
    async onDelete() {
      const self = this
      users
        .deleteRole(self.role.id)
        .then(res => {
          self.serverErrors = []
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          self.$emit('delete')
        })
        .catch(err => {
          self.formErrors = true
          self.confirmDelete = false
          if (err.response.status == 422) {
            self.serverErrors = err.response.data.errors
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          }
        })
    },
    getPermissionIndex(area, section, perm) {
      const permission = `${area.key}.${section.feature}.${perm}`

      return this.$_.findIndex(this.role.permissions, p => {
        return p === permission
      })
    },
    onSelectPermission(event, area, section, perm) {
      const index = this.getPermissionIndex(area, section, perm)

      if (event && index < 0) {
        this.role.permissions.push(`${area.key}.${section.feature}.${perm}`)
      }

      if (event === false && index >= 0) {
        this.role.permissions.splice(index, 1)
      }
    },
    isAllSelected(area, section) {
      for (let i = 0, len = section.permissions.length; i < len; i++) {
        const index = this.getPermissionIndex(area, section, section.permissions[i])
        if (index < 0) {
          return false
        }
      }
      return true
    },
    selectAll(area, section) {
      this.$_.forEach(section.permissions, p => {
        const index = this.getPermissionIndex(area, section, p)
        if (index < 0) {
          this.role.permissions.push(`${area.key}.${section.feature}.${p}`)
        }
      })
    },
    deselectAll(area, section) {
      this.$_.forEach(section.permissions, p => {
        const index = this.getPermissionIndex(area, section, p)
        if (index >= 0) {
          this.role.permissions.splice(index, 1)
        }
      })
    },
  },
}
</script>
