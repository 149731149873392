<template>
  <b-container class="ml-auto mb-2" fluid>
    <div v-if="$acl.can('usersroles.users.read')">
      <page-loader v-if="loading" type="list" :fluid="true" />
      <portal to="nav-bar-left-column">
        <page-title title="Users" subtitle="Registered users" />
      </portal>
      <portal to="nav-bar-right-column" v-if="$acl.can('usersroles.users.create') && !loading">
        <router-link :to="{ name: 'NewUser' }" class="btn btn-primary"><feather-icon icon="PlusIcon" class="mr-25" /> Create user</router-link>
      </portal>
      <b-card no-body class="mt-1" v-if="!loading">
        <b-card-body>
          <div class="d-flex justify-content-between align-items-center flex-wrap">
            <div class="flex-fill">
              <b-form inline @submit.prevent>
                <b-input-group class="w-25">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input placeholder="Search" @keyup.enter="onTextSearch" v-model="searchText" />
                </b-input-group>
              </b-form>
            </div>
          </div>
        </b-card-body>
        <b-table
          striped
          hover
          responsive
          class="position-relative"
          :current-page="currentPage"
          :items="users"
          :fields="columns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="false"
          @sort-changed="onSortChanged"
          no-local-sorting
        >
          <template #head($selection)="data">
            <b-form-checkbox @change="onSelectAllUsers" />
          </template>
          <template #cell($selection)="data">
            <b-form-checkbox @change="onSelectUser($event, data.item.id)" :checked="isUserSelected(data.item.id)" />
          </template>
          <template #cell(createdAt)="data">
            <span v-html="data.value"></span>
          </template>
          <template #cell(modifiedAt)="data">
            <span v-html="data.value"></span>
          </template>
          <template #cell(lastName)="data">
            <router-link :to="{ name: 'EditUser', params: { id: data.item.id } }">{{ data.value }}</router-link>
          </template>
          <template #cell(isActive)="data">
            <b-badge :variant="'light-' + (data.item.isActive ? 'success' : 'dark')">{{ data.item.isActive ? 'active' : 'inactive' }}</b-badge>
          </template>
          <template #cell($actions)="data">
            <b-button
              @click="$router.push({ name: 'EditUser', params: { id: data.item.id } })"
              variant="outline-secondary"
              size="sm"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" @change="onRowsPerPageChanged" />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-if="totalPages > 1"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @change="onPageChanged"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div v-else>
      <forbidden />
    </div>
  </b-container>
</template>
<script>
import { users } from '@/api/index'

export default {
  name: 'Users',
  data() {
    return {
      users: [],
      selectedUsers: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      searchText: '',
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      totalPages: 1,
      loading: false,
      columns: [
        // {
        //   key: '$selection',
        //   label: '',
        //   sortable: false,
        //   tdClass: 'icon-cell',
        // },
        {
          key: 'lastName',
          label: 'Name',
          sortable: true,
          formatter: (value, key, item) => {
            return `${item.firstName} ${item.lastName}`
          },
        },
        {
          key: 'email',
          label: 'Email',
          value: 'email',
          sortable: true,
        },
        {
          key: 'username',
          label: 'Username',
          value: 'username',
          sortable: true,
        },
        {
          key: 'isActive',
          label: 'Active',
          value: 'isActive',
          sortable: true,
        },
        {
          key: 'roles',
          label: 'Roles',
          value: 'roles',
          sortable: false,
        },
        {
          key: 'createdAt',
          label: 'Created At',
          value: 'createAt',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
        },
        {
          key: 'modifiedAt',
          label: 'Modified At',
          value: 'modifiedAt',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              let dateString = this.$moment(value).format('YYYY-MM-DD HH:mm')
              return `<small>${dateString}</small>`
            }
            return value
          },
        },
        {
          key: '$actions',
          label: '',
          tdClass: 'action-cell',
        },
      ],
    }
  },
  watch: {
    $route(to, from) {
      this.fetchData(to.query)
    },
  },
  computed: {},
  mounted() {},
  created() {
    let self = this
    self.fetchData(self.$route.query)
  },
  methods: {
    updateQueryRoute() {
      const query = this.$route.query
      const filters = this.getCurrentFilters()

      if (!this.$_.isEqual(query, filters)) {
        this.$router.push({ query: filters }).catch(() => {})
      }
    },
    async fetchData(query) {
      const self = this
      self.loading = true
      await users.getUsers(query).then(res => {
        self.users = res.data.data
        self.totalRows = res.data.metadata.count
        self.totalPages = res.data.metadata.totalPages
        self.currentPage = res.data.metadata.currentPage == 0 ? 1 : res.data.metadata.currentPage
        self.loading = false
      })
    },
    getCurrentFilters() {
      return {
        page: this.currentPage,
        size: this.perPage,
        search: this.searchText,
        sort: `${this.sortBy}|${this.sortDirection}`,
      }
    },
    onTextSearch(e) {
      this.currentPage = 1
      this.updateQueryRoute()
    },
    onLocaleChange(e) {
      this.currentPage = 1
      this.updateQueryRoute()
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy
      this.sortDesc = e.sortDesc
      this.sortDirection = e.sortDesc ? 'desc' : 'asc'
      this.updateQueryRoute()
    },
    onPageChanged(e) {
      this.currentPage = e
      this.updateQueryRoute()
    },
    onRowsPerPageChanged(e) {
      this.perPage = e
      this.updateQueryRoute()
    },
    onSelectAllUsers(val) {
      if (val) {
        this.selectedUsers = this.users.map(c => c.id)
      } else {
        this.selectedUsers = []
      }
    },
    onSelectUser(val, id) {
      let index = this.$_.findIndex(this.selectedUsers, function(c) {
        return c == id
      })

      if (val) {
        if (index < 0) {
          this.selectedUsers.push(id)
        }
      } else {
        if (index >= 0) {
          this.selectedUsers.splice(index, 1)
        }
      }
    },
    isUserSelected(id) {
      let index = this.$_.findIndex(this.selectedUsers, function(c) {
        return c == id
      })

      return index >= 0
    },
  },
}
</script>
